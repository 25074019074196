.App {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .message {
    animation: fadeIn 5s;
  }
}

.App-header {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
